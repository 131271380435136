<template>
  <th :class="classes">
    <slot />
  </th>
</template>

<script>
export default {
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    collapsing: {
      type: Boolean,
      default: false,
    },
    celled: {
      type: Boolean,
      default: false,
    },
    bottomAligned: {
      type: Boolean,
      default: false,
    },
    leftAligned: {
      type: Boolean,
      default: false,
    },
    centerAligned: {
      type: Boolean,
      default: false,
    },
    one: {
      type: Boolean,
      default: false,
    },
    two: {
      type: Boolean,
      default: false,
    },
    three: {
      type: Boolean,
      default: false,
    },
    four: {
      type: Boolean,
      default: false,
    },
    five: {
      type: Boolean,
      default: false,
    },
    six: {
      type: Boolean,
      default: false,
    },
    seven: {
      type: Boolean,
      default: false,
    },
    eight: {
      type: Boolean,
      default: false,
    },
    nine: {
      type: Boolean,
      default: false,
    },
    ten: {
      type: Boolean,
      default: false,
    },
    eleven: {
      type: Boolean,
      default: false,
    },
    twelve: {
      type: Boolean,
      default: false,
    },
    thirteen: {
      type: Boolean,
      default: false,
    },
    fourteen: {
      type: Boolean,
      default: false,
    },
    fifteen: {
      type: Boolean,
      default: false,
    },
    sixteen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'ui sticky': this.sticky,
        collapsing: this.collapsing,
        celled: this.celled,
        'bottom aligned': this.bottomAligned,
        'left aligned': this.leftAligned,
        'center aligned': this.centerAligned,
        'one wide': this.one,
        'two wide': this.two,
        'three wide': this.three,
        'four wide': this.four,
        'five wide': this.five,
        'six wide': this.six,
        'seven wide': this.seven,
        'eight wide': this.eight,
        'nine wide': this.nine,
        'ten wide': this.ten,
        'eleven wide': this.eleven,
        'twelve wide': this.twelve,
        'thirteen wide': this.thirteen,
        'fourteen wide': this.fourteen,
        'fifteen wide': this.fifteen,
        'sixteen wide': this.sixteen,
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/borders.less';

.sticky {
  position: sticky;
  top: 0;
}

.celled {
  border-right: @light-border;

  &:last-child {
    border-right: none;
  }
}
</style>
