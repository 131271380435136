<template>
  <container>
    <page-header centered>
      <h1>Select People to Import</h1>
    </page-header>
    <loading v-if="loading" :loading="loading" />
    <grid v-else centered>
      <grid-column eight centered class="table-column">
        <g-table
          :class="['people-table', { empty: slackUsers.length === 0 }]"
          compact
        >
          <table-head>
            <table-row>
              <table-header sticky>
                <checkbox
                  class="everyone-checkbox"
                  name="Everyone"
                  :initial-value="isMasterChecked"
                  @update="checkMasterCheckbox"
                />
              </table-header>
              <table-header sticky>
                <flex justify="space-between" align="center">
                  <span class="table-title">Everyone</span>
                  <span> {{ numberOfUsersToImport }} People </span>
                </flex>
              </table-header>
            </table-row>
          </table-head>
          <table-body>
            <table-row v-for="user in slackUsers" :key="user.id">
              <table-cell>
                <checkbox
                  child
                  :initial-value="user.isIncludedInImport"
                  :name="user.real_name"
                  @update="(value) => (user.isIncludedInImport = value)"
                />
              </table-cell>
              <table-cell>
                <h4 class="ui image header">
                  <g-image
                    :src="user.profile.image_48"
                    alt="Slack avatar"
                    avatar
                  />
                  <div class="content">
                    {{ user.real_name }}
                  </div>
                </h4>
              </table-cell>
            </table-row>
            <table-row v-if="!slackUsers.length">
              <table-cell />
              <table-cell>
                <p>Everyone has already been imported to Gather</p>
              </table-cell>
            </table-row>
          </table-body>
        </g-table>
      </grid-column>
      <grid-column centered sixteen>
        <p class="import-note">
          Note: People will not be notified that they have been added to Gather
        </p>
        <flex justify="space-around">
          <btn
            variant="default"
            basic
            size="large"
            @click.native="$router.push({ name: 'people_import' })"
          >
            Back
          </btn>
          <btn size="large" @click.native="importPeople"> Import </btn>
        </flex>
      </grid-column>
    </grid>
  </container>
</template>

<script>
import { sortBy, reject, toLower } from 'lodash'
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import gTable from '@/components/v2/table/table.vue'
import tableHead from '@/components/v2/table/head.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableHeader from '@/components/v2/table/header.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'
import checkbox from '@/components/v2/checkbox.vue'
import gImage from '@/components/v2/image.vue'
import btn from '@/components/v2/btn.vue'
import container from '@/components/v2/container.vue'
import loading from '@/components/v2/loading.vue'
import flex from '@/components/v2/flex.vue'

import slackLogo from '@/assets/img/integrations/slack.png'

import { toast } from '@/toasts'
import { api } from '@/api'

export default {
  components: {
    pageHeader,
    grid,
    gridColumn,
    gTable,
    tableHead,
    tableCell,
    tableHeader,
    tableBody,
    tableRow,
    checkbox,
    gImage,
    btn,
    container,
    loading,
    flex,
  },
  data() {
    return {
      slackLogo,
      slackUsers: [],
      users: [],
      isMasterChecked: true,
      loading: false,
    }
  },
  computed: {
    numberOfUsersToImport() {
      return this.slackUsers.filter((user) => user.isIncludedInImport).length
    },
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      try {
        this.loading = true
        await this.getUsers()
        await this.getSlackUsers()
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
    },
    async getUsers() {
      let response
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              status: ['active', 'onboarding'],
            }
          )
          this.users = this.users.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
    },
    async getSlackUsers() {
      let slackUsers = []
      try {
        slackUsers = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/slack/users`
          )
        ).data
      } catch (error) {
        toast.error(error)
      }

      // only show slack users who don't already exist in Gather
      this.slackUsers = sortBy(
        reject(
          slackUsers.map((slackUser) => ({
            ...slackUser,
            ...{
              isIncludedInImport: true,
            },
          })),
          (slackUser) =>
            this.users.some(
              (user) => toLower(user.email) === toLower(slackUser.profile.email)
            )
        ),
        (slackUser) => toLower(slackUser.real_name)
      )
    },

    async importPeople() {
      try {
        this.loading = true
        if (this.numberOfUsersToImport) {
          await api.post(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/slack/import`,
            {
              slackUserIds: this.slackUsers.flatMap((user) =>
                user.isIncludedInImport ? user.id : []
              ),
            }
          )
        }
        this.$router.push({ name: 'people' })
      } catch (error) {
        toast.error(error)
      } finally {
        this.loading = false
      }
    },
    checkMasterCheckbox(value) {
      this.isMasterChecked = value
      this.slackUsers = this.slackUsers.map((user) => ({
        ...user,
        ...{
          isIncludedInImport: this.isMasterChecked,
        },
      }))
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/borders.less';

.table-title {
  font-size: @title-font-size;
}

.table-column {
  height: calc(70vh - 3em);
  padding: 0 !important;
  margin-top: 1em;
  overflow-y: auto;
  border: @standard-border;
  border-radius: @standard-border-radius;
}

.empty {
  border: none;
}

.everyone-checkbox {
  vertical-align: text-top;
}

.people-table {
  border: none;
}
</style>
